import { get, reduce, isNil, transform, isEqual } from "lodash-es"

const getSaleStoppedOtaIds = (changedSaleStop = {}) => {
  return transform(
    changedSaleStop,
    (ids, saleStop, otaID) => {
      if (saleStop) ids.push(Number(otaID))

      return ids
    },
    []
  )
}

/* check if OTA sale_stop list from backend is different from updated on frontend */
const isOtasSaleStoppedChanged = (carClassId, day, changedInventories, inventories) => {
  const oldOtaIds = getSaleStoppedOtaIds(get(inventories, `${carClassId}.inventory_data.${day}.sale_stop`))
  const saleStopsFromChangedInventories = get(changedInventories, `${carClassId}.${day}.sale_stop`)
  const saleStoppedOtaIds = getSaleStoppedOtaIds(saleStopsFromChangedInventories)

  return !isEqual(oldOtaIds.sort(), saleStoppedOtaIds.sort()) && !isNil(saleStopsFromChangedInventories)
}

const getAvailableInventory = ({ existingShared, changedShared, totalBooked }) => {
  const shared = isNil(changedShared) ? existingShared : changedShared

  return (shared || 0) - (totalBooked || 0)
}

const dayInventoryObject = (carClassId, day, { groupedTotalBooked, changedInventories, inventories }) => {
  const totalBooked = get(groupedTotalBooked, `${carClassId}.${day}`)
  const changedShared = get(changedInventories, `${carClassId}.${day}.shared_inventory_limit`)

  const existingShared = get(inventories, `${carClassId}.inventory_data.${day}.shared_inventory_limit`)

  const availableInventory = getAvailableInventory({
    existingShared,
    changedShared,
    totalBooked
  })

  const isAvailableInventoryNegative = availableInventory < 0

  const isOtaSaleStopChanged = isOtasSaleStoppedChanged(carClassId, day, changedInventories, inventories)

  const isInventoryChanged = !isNil(changedShared) && existingShared !== changedShared

  const isInvalid =
    (isAvailableInventoryNegative && !isOtaSaleStopChanged) ||
    (isInventoryChanged && isOtaSaleStopChanged && isAvailableInventoryNegative)

  return {
    totalBooked,
    availableInventory,
    isInvalid,
    isChanged: isInventoryChanged
  }
}

const calculationsByDays = (
  carClassId,
  { formattedDateRange, groupedTotalBooked, changedInventories, inventories }
) => {
  return reduce(
    formattedDateRange,
    (objByDays, day) => {
      objByDays[day] = dayInventoryObject(carClassId, day, {
        groupedTotalBooked,
        changedInventories,
        inventories
      })

      return objByDays
    },
    {}
  )
}

export default ({ carClassesForPage, formattedDateRange, groupedTotalBooked, changedInventories, inventories }) => {
  return reduce(
    carClassesForPage,
    (objByCarClasses, { id }) => {
      objByCarClasses[id] = calculationsByDays(id, {
        formattedDateRange,
        groupedTotalBooked,
        changedInventories,
        inventories
      })

      return objByCarClasses
    },
    {}
  )
}
